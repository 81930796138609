import React from 'react'
import { graphql } from 'gatsby'

// components
import DefaultLayout from '../components/Layout/default'
import SEO from '../components/SEO'
import CardWithTabList from '../components/Article/CardWithTabList'
import Tags from '../components/Tags'

// type
import { IndexTemplateQuery } from '../../types/graphql-types'
interface IndexProps {
  data: IndexTemplateQuery
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
  return (
    <DefaultLayout title="トップ">
      <SEO
        title="トップ"
        keywords={[
          '経済',
          'ブログ',
          'Notion',
          '投資',
          'プログラミング',
          'デザイン',
        ]}
      />

      <div className="container mx-auto">
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-col w-full sm:w-2/3 sm:mx-5">
            <CardWithTabList
              firstTabKeyVisualGatsbyImageData={
                data.kvFile?.childImageSharp?.gatsbyImageData
              }
              allArticleEdges={data.allMarkdownRemark.edges}
            />
          </div>

          <div className="flex flex-col w-full sm:w-1/3 mt-10 sm:mt-0 px-5 sm:pl-10">
            <h2 className="text-2xl">すべてのタグ</h2>
            <Tags />
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexTemplate {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            templateKey
            date(formatString: "YYYY/MM/DD")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    kvFile: file(relativePath: { eq: "flavor_wheel.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
