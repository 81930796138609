import React, { useState, useRef } from 'react'
import classNames from 'classnames'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import SwipeableViews from 'react-swipeable-views'
import _ from 'lodash'
import { Link } from 'gatsby'
import ScrollContainer from 'react-indiana-drag-scroll'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Element, animateScroll } from 'react-scroll'

// components
import CardGrid from './CardGrid'
import About from '../Hero/About'
import { IGatsbyImageData } from 'gatsby-plugin-image'

// props interface
interface CardWithTabListProps {
  firstTabKeyVisualGatsbyImageData: IGatsbyImageData
  allArticleEdges?: any
  className?: string
}

const CardWithTabList: React.FC<CardWithTabListProps> = ({
  firstTabKeyVisualGatsbyImageData,
  allArticleEdges = [],
  className = '',
}) => {
  const topRef = useRef(null)
  const navRef = useRef(null)

  const OTHER_TAB_TITLES = [
    '経済',
    'Notion',
    '投資',
    '書評',
    'デザイン',
    'システムシンキング',
    'サイモン',
    'ユダヤの商法',
    '令和のハッカー',
    'エッセイ',
    '哲学',
  ]

  const moreEdges = _.slice(allArticleEdges, 0, 18)
  const otherTabTitles = OTHER_TAB_TITLES.map((tabTitle) => {
    const targetTitleNumber = allArticleEdges.filter((edge: any) => {
      const { tags } = edge.node.frontmatter
      return tags.includes(tabTitle)
    }).length

    return `${tabTitle}(${targetTitleNumber})`
  })
  const otherTabTitleEdges = OTHER_TAB_TITLES.map((tabTitle) =>
    allArticleEdges.filter((edge: any) => {
      const { tags } = edge.node.frontmatter
      return tags.includes(tabTitle)
    })
  )

  const [tabIndex, setTabIndex] = useState(0)
  const [tabTitles, setTabTitles] = useState(
    _.flatten([`最新(${allArticleEdges.length})`, otherTabTitles])
  )

  // 先頭に最新の記事配列を追加
  otherTabTitleEdges.unshift(moreEdges)
  const [tabArticleEdges, setTabArticleEdges] = useState(otherTabTitleEdges)

  const classes = classNames('', className)

  /**
   * 選択されたタブが中央にくるようにスクロールバーを調整する
   *
   * @param element
   */
  const centerScrollX = (element: any) => {
    // ① クリックされたタブがスクロール領域(nav)の中で中央に来るような左右の余白を計算する
    // +-------------+
    // |<-->|Tab|<-->|
    // +-------------+
    // ② 次にそのタブの左からの位置を取得する
    // |
    // |<------------->|Tab|
    // |
    // ③ スクロール領域(nav)自体の左マージンをスクロール量から削除する
    // |
    // |<->|nav|
    // |
    // ②-①-③が必要なスクロール量となる
    const tabWidth = element.clientWidth
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const navWidth = navRef.current.clientWidth
    // ①左右の余白
    const margin = (navWidth - tabWidth) / 2
    // ②タブの左からの位置(PCの場合はnavWidth分を削除する必要がある)
    const { offsetLeft } = element

    // SP版の場合とPCの場合でスクロール量を変更する
    if (window.innerWidth > 640) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      navRef.current.scrollTo({
        left: offsetLeft - margin,
        top: 0,
        behavior: 'smooth',
      })
    } else {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      navRef.current.scrollTo({
        left: offsetLeft - margin,
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  /**
   * タブを切り替えた場合は最上部まで自動でスクロールする
   */
  const centerScrollY = () => {
    // タブ切り替えの部分までスクロールする
    if (window.scrollY > 0) {
      animateScroll.scrollToTop({
        duration: 1000,
        delay: 400,
        smooth: 'easeOutCubic',
        offset: -20,
      })
    }
  }

  /**
   * タブ切り替え時のIndex切り替え
   *
   * @param index
   */
  const handleChangeIndex = (index: any) => {
    setTabIndex(index)
    centerScrollY()
    // activeなtabのindexがnavの中央に来るようにスクロールを調整
    centerScrollX(document.getElementById(`tabList-button-${index}`))
  }

  /**
   * タブの選択、非選択時のテキストとボーターの色を変更
   *
   * @param currentIndex
   * @param nextTabIndex
   * @returns {string}
   */
  const updateTabStyle = (currentIndex: any, nextTabIndex: any) => {
    if (currentIndex === nextTabIndex) {
      return 'text-black bg-backgroundColor1 ring-4 ring-tintColor2'
    }
    return 'text-tintColor1 bg-backgroundColor1'
  }

  /**
   * タブリストを返却
   *
   * @param currentTabIndex
   * @param tabTitlesArray
   * @returns {JSX.Element}
   */
  const renderTabList = (currentTabIndex: any, tabTitlesArray: any) => (
    <ScrollContainer
      component="nav"
      className="flex flex-row flex-no-wrap overflow-x-auto scrollbar-hidden my-2"
      innerRef={navRef}
    >
      {tabTitlesArray.map((title: any, index: any) => (
        <button
          id={`tabList-button-${index}`}
          key={`tabList-button-${index}`}
          type="button"
          onClick={(e) => {
            handleChangeIndex(index)
          }}
          className={`py-2 px-3 my-1 mx-2 rounded-lg block focus:outline-none ${updateTabStyle(
            index,
            currentTabIndex
          )}`}
        >
          <span className="whitespace-pre">{title}</span>
        </button>
      ))}
    </ScrollContainer>
  )

  return (
    <Element name="article-tab-section">
      <div className={classes} ref={topRef}>
        {/* タブ切り替えの部分 */}
        {renderTabList(tabIndex, tabTitles)}

        {/* タブの中身の部分 */}

        <SwipeableViews
          index={tabIndex}
          animateHeight
          onChangeIndex={handleChangeIndex}
          // springConfig={{
          //   duration: '0.4s',
          //   easeFunction: 'easeInOutCirc',
          //   delay: '0s',
          // }}
        >
          {tabArticleEdges.map((articleEdges, index) => (
            <div key={`tab-list-${index}`} className="z-10">
              {/* 最新のタブの場合だけ紹介画面を出す */}
              {index === 0 ? (
                <div className="mb-10">
                  <About gatsbyImageData={firstTabKeyVisualGatsbyImageData}>
                    <div>
                      <div className="mx-5 my-20 sm:my-40">
                        <h1 className="text-2xl sm:text-3xl bg-backgroundColor1 inline leading-10 font-bold">
                          経済オタクエンジニアの
                          <br className="" />
                          2055年から来たごんじっちが
                          <br className="" />
                          一味違う情報を届ける
                          <br className="" />
                          経済・書評・デザインブログ
                        </h1>
                      </div>
                    </div>
                  </About>
                </div>
              ) : (
                <div />
              )}

              <CardGrid articleEdges={articleEdges} className="mx-5 sm:mx-0">
                {/* 最新のタブの場合とされ以外でリンクを分ける */}
                {index === 0 ? (
                  <div className="text-center m-5">
                    <Link
                      to="/blogs/2"
                      className="block p-3 md:inline-block md:mt-0 text-gray-800 hover:text-linkColor hover:underline mr-4"
                    >
                      もっと見る
                    </Link>
                  </div>
                ) : (
                  <div className="text-center m-5">
                    <Link
                      to="/tags/"
                      className="block p-3 md:inline-block md:mt-0 text-gray-800 hover:text-linkColor hover:underline mr-4"
                    >
                      すべてのタグを見る
                    </Link>
                  </div>
                )}
              </CardGrid>
            </div>
          ))}
        </SwipeableViews>
      </div>
    </Element>
  )
}

export default CardWithTabList
