/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import classNames from 'classnames'

// props interface
interface AboutProps {
  articleEdges?: any
  children?: React.ReactNode
  className?: string
  showOnlyFirst?: boolean
  [k: string]: any
}

const About: React.FC<AboutProps> = (props) => {
  /**
   * defaultProps
   *
   * backgroundImageFluid{String}背景画像のfluid
   * className{String} className
   * overrideClassName{String} classNameを上書きする場合のClassName
   */
  ;(About as AboutProps).defaultProps = {
    backgroundImageFluid: '',
    className: '',
    overrideClassName: '',
  }

  const { gatsbyImageData, children, className, overrideClassName } = props

  // gatsby-background-imageのためにブリッジする。
  // @see https://www.gatsbyjs.com/plugins/gatsby-background-image/#gatsby-3--gatsby-plugin-image
  const bgImage = convertToBgImage(gatsbyImageData)

  let classes = classNames('overflow-hidden', className)

  // overrideClassNameが存在する場合は、指定されたclassNameのみを用いる
  if (overrideClassName) {
    classes = classNames(overrideClassName)
  }

  return (
    <BackgroundImage className={classes} {...bgImage}>
      {children}
    </BackgroundImage>
  )
}

export default About
